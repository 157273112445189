import { config } from '../config'

export default function Globe () {
  return (
    <>
      <mesh>
        <icosahedronBufferGeometry args={[config.scene.sphereRadius, 16, 16]} />
        <meshPhysicalMaterial
          color={config.scene.globeColor}
          roughness={0.5}
          metalness={0.5}
          // emissiveIntensity={1.1}
          emissive={config.scene.globeColor}
          // reflectivity={1}
          opacity={1}
          transparent
        />
      </mesh>
      <mesh>
        <icosahedronBufferGeometry args={[config.scene.sphereRadius, 16, 16]} />
        <meshBasicMaterial
          opacity={0.1}
          wireframe
          color={0xffffff}
          transparent
        />
      </mesh>
    </>
  )
}
