
import React, { useEffect, useRef } from 'react'
import {
  EffectComposer,
  Bloom,
  Vignette,
  BrightnessContrast
} from '@react-three/postprocessing'
import LensFlare from './UlmateLensFlare'
import { useFrame } from '@react-three/fiber'
import * as TWEEN from '@tweenjs/tween.js'

import { config } from '../config'

const Post = () => {
  const brightnessRef = useRef()

  // constructor
  useEffect(() => {
    const o = { brightness: -0.4 }
    new TWEEN.Tween(o)
      .to({ brightness: 0 }, 5000)
      .onUpdate((e) => {
        if (brightnessRef.current) {
          brightnessRef.current.brightness = e.brightness
        }
      })
      .start()
  }, [])

  useFrame(() => {
    TWEEN.update()
  })

  return (
    <EffectComposer>
      {config.scene.lensFlare && (
        <LensFlare />
      )}
      <Bloom intensity={2} luminanceThreshold={0.0} luminanceSmoothing={0.9} height={800} kernelSize={2} />
      <BrightnessContrast ref={brightnessRef} brightness={-0.4} />
      <Vignette eskil={false} offset={0.15} darkness={0.8} />
    </EffectComposer>
  )
}

export default Post
