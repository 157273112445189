import { useEffect, useRef, useState } from 'react'
import { useModalStore } from '../state/ModalStore'
import { getLS as l } from '../languageStrings'

export default function Description () {
  const zoom = useModalStore((state) => state.zoom)

  const [hide, setHide] = useState(false)
  const [removed, setRemoved] = useState(false)

  const removeTimeout = useRef()

  useEffect(() => {
    if (zoom > 0 && zoom < 6) {
      setHide(true)
      clearTimeout(removeTimeout.current)
      removeTimeout.current = setTimeout(() => {
        setRemoved(true)
      }, 1000)
    } else {
      setHide(false)
      clearTimeout(removeTimeout.current)
      removeTimeout.current = setTimeout(() => {
        setRemoved(false)
      }, 500)
    }
  }, [zoom])

  return (
    <>
      <div className={`${hide ? 'hide ' : ''}description ${removed ? 'removed ' : ''}`}>
        <h1 className='title'>{l.description_title}</h1>
        <p>{l.description_body}</p>
        <p>
          <a href='https://www.essentialcardano.io/article/cip-1694-community-workshops-the-line-up' target='_blank' rel='noreferrer'>
            {l.description_readMore}
          </a>
        </p>
      </div>
    </>
  )
}
