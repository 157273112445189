import { useProgress } from '@react-three/drei'
import { useEffect } from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'

import 'react-circular-progressbar/dist/styles.css'

export default function Loader ({ wrapperRef }) {
  const { active, progress, errors, item, loaded, total } = useProgress()

  // useEffect(() => {
  //   console.log({ progress, errors, item, loaded, total, active })
  // }, [errors, item, loaded, total, active])

  const ringCount = 30
  const ringProgressDelta = 100 / ringCount

  const styles = buildStyles({
    strokeLinecap: 'butt',
    pathColor: '#ffffff',
    trailColor: 'rgba(0,0,0,0)'
  })

  return (
    <>
      <div className='loader-wrapper'>
        <div
          className='loader-container'
        >
          <div className='first-ring ring-1'>
            <CircularProgressbar value={progress} maxValue={ringProgressDelta * 30} strokeWidth={20} styles={styles} />
          </div>
          <div className='first-ring ring-2'>
            <CircularProgressbar value={progress} maxValue={ringProgressDelta * 29} strokeWidth={20} styles={styles} />
          </div>
          <div className='first-ring ring-3'>
            <CircularProgressbar value={progress} maxValue={ringProgressDelta * 28} strokeWidth={20} styles={styles} />
          </div>
          <div className='first-ring ring-4'>
            <CircularProgressbar value={progress} maxValue={ringProgressDelta * 27} strokeWidth={20} styles={styles} />
          </div>
          <div className='first-ring ring-5'>
            <CircularProgressbar value={progress} maxValue={ringProgressDelta * 26} strokeWidth={20} styles={styles} />
          </div>
          <div className='first-ring ring-6'>
            <CircularProgressbar value={progress} maxValue={ringProgressDelta * 25} strokeWidth={20} styles={styles} />
          </div>

          <div className='second-ring ring-7'>
            <CircularProgressbar value={progress} maxValue={ringProgressDelta * 24} strokeWidth={22} styles={styles} />
          </div>
          <div className='second-ring ring-8'>
            <CircularProgressbar value={progress} maxValue={ringProgressDelta * 23} strokeWidth={22} styles={styles} />
          </div>
          <div className='second-ring ring-9'>
            <CircularProgressbar value={progress} maxValue={ringProgressDelta * 22} strokeWidth={22} styles={styles} />
          </div>

          <div className='second-ring ring-10'>
            <CircularProgressbar value={progress} maxValue={ringProgressDelta * 21} strokeWidth={22} styles={styles} />
          </div>
          <div className='second-ring ring-11'>
            <CircularProgressbar value={progress} maxValue={ringProgressDelta * 20} strokeWidth={22} styles={styles} />
          </div>
          <div className='second-ring ring-12'>
            <CircularProgressbar value={progress} maxValue={ringProgressDelta * 19} strokeWidth={22} styles={styles} />
          </div>

          <div className='third-ring ring-13'>
            <CircularProgressbar value={progress} maxValue={ringProgressDelta * 18} strokeWidth={25} styles={styles} />
          </div>
          <div className='third-ring ring-14'>
            <CircularProgressbar value={progress} maxValue={ringProgressDelta * 17} strokeWidth={25} styles={styles} />
          </div>
          <div className='third-ring ring-15'>
            <CircularProgressbar value={progress} maxValue={ringProgressDelta * 16} strokeWidth={25} styles={styles} />
          </div>
          <div className='third-ring ring-16'>
            <CircularProgressbar value={progress} maxValue={ringProgressDelta * 15} strokeWidth={25} styles={styles} />
          </div>
          <div className='third-ring ring-17'>
            <CircularProgressbar value={progress} maxValue={ringProgressDelta * 14} strokeWidth={25} styles={styles} />
          </div>
          <div className='third-ring ring-18'>
            <CircularProgressbar value={progress} maxValue={ringProgressDelta * 13} strokeWidth={25} styles={styles} />
          </div>

          <div className='fourth-ring ring-19'>
            <CircularProgressbar value={progress} maxValue={ringProgressDelta * 12} strokeWidth={28} styles={styles} />
          </div>
          <div className='fourth-ring ring-20'>
            <CircularProgressbar value={progress} maxValue={ringProgressDelta * 11} strokeWidth={28} styles={styles} />
          </div>
          <div className='fourth-ring ring-21'>
            <CircularProgressbar value={progress} maxValue={ringProgressDelta * 10} strokeWidth={28} styles={styles} />
          </div>
          <div className='fourth-ring ring-22'>
            <CircularProgressbar value={progress} maxValue={ringProgressDelta * 9} strokeWidth={28} styles={styles} />
          </div>
          <div className='fourth-ring ring-23'>
            <CircularProgressbar value={progress} maxValue={ringProgressDelta * 8} strokeWidth={28} styles={styles} />
          </div>
          <div className='fourth-ring ring-24'>
            <CircularProgressbar value={progress} maxValue={ringProgressDelta * 7} strokeWidth={28} styles={styles} />
          </div>

          <div className='fifth-ring ring-25'>
            <CircularProgressbar value={progress} maxValue={ringProgressDelta * 6} strokeWidth={30} styles={styles} />
          </div>
          <div className='fifth-ring ring-26'>
            <CircularProgressbar value={progress} maxValue={ringProgressDelta * 5} strokeWidth={30} styles={styles} />
          </div>
          <div className='fifth-ring ring-27'>
            <CircularProgressbar value={progress} maxValue={ringProgressDelta * 4} strokeWidth={30} styles={styles} />
          </div>
          <div className='fifth-ring ring-28'>
            <CircularProgressbar value={progress} maxValue={ringProgressDelta * 3} strokeWidth={30} styles={styles} />
          </div>
          <div className='fifth-ring ring-29'>
            <CircularProgressbar value={progress} maxValue={ringProgressDelta * 2} strokeWidth={30} styles={styles} />
          </div>
          <div className='fifth-ring ring-30'>
            <CircularProgressbar value={progress} maxValue={ringProgressDelta * 1} strokeWidth={30} styles={styles} />
          </div>
        </div>
      </div>
    </>
  )
}
