import { useEffect, useState } from 'react'

import { useModalStore } from '../state/ModalStore'

import locations from '../data/workshops.json'

export default function DateSelector () {
  const setEventDetails = useModalStore((state) => state.setEventDetails)
  const eventDetails = useModalStore((state) => state.eventDetails)
  const modalOpen = useModalStore((state) => state.modalOpen)
  const setModalOpen = useModalStore((state) => state.setModalOpen)
  const setZoom = useModalStore((state) => state.setZoom)
  const zoom = useModalStore((state) => state.zoom)

  const [months, setMonths] = useState([])

  useEffect(() => {
    const monthObj = {}
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ]

    // sort location data by date
    const filteredLocations = locations.filter(event => event.date !== '')
    filteredLocations.sort((a, b) => new Date(a.date) - new Date(b.date))

    // create nested array
    filteredLocations.forEach((el, i) => {
      if (typeof el.date !== 'undefined' && el.date !== '') {
        const month = new Date(el.date).getMonth()
        if (typeof monthObj[monthNames[month]] === 'undefined') {
          monthObj[monthNames[month]] = []
        }
        monthObj[monthNames[month]].push(el)
      }
    })

    setMonths(monthObj)
  }, [locations])

  return (
    <>
      <div className='month-selector-wrapper'>
        <div className='month-selector-inner'>
          {Object.keys(months).map((month, monthIndex) => {
            return (
              <div key={monthIndex} className='month-container'>
                <div className='month-markers'>
                  <p>{month}</p>
                  {Object.keys(months[month]).map((date, index) => {
                    return (
                      <span
                        className={eventDetails && eventDetails.uid === months[month][index].uid && modalOpen ? 'selected' : ''}
                        data-tooltip-id='event-tooltip'
                        data-tooltip-variant='light'
                        data-tooltip-html={
                        `<span class='tooltip-content'><p>${months[month][index].location}</p>
                        <p>${months[month][index].date}</p></span>
                        `
                      }
                        key={index}
                        onClick={() => {
                          setModalOpen(false)
                          setEventDetails(months[month][index])
                          setZoom(4)
                        }}
                      >
                        <span />
                      </span>
                    )
                  })}
                </div>
              </div>
            )
          }
          )}
        </div>
      </div>
      <div className='month-selector-bg' style={{ opacity: Math.min(1, Math.max(0, 5 / zoom)) }} />
    </>
  )
}
