
import React, { Suspense, useEffect, useRef } from 'react'
import { NoToneMapping } from 'three'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { Tooltip } from 'react-tooltip'
import { isMobile } from 'react-device-detect'
import { SheetProvider, PerspectiveCamera } from '@theatre/r3f'
import { getProject } from '@theatre/core'
import { Perf } from 'r3f-perf'

// css
import './App.css'
import 'react-tooltip/dist/react-tooltip.css'

import { Environment, OrbitControls, Stars } from '@react-three/drei'
import ParticleRenderer from './components/ParticleRenderer'
import { config } from './config'
import Markers from './components/Markers'
import Post from './components/Post'
import Main from './components/Main'
import logoImg from './assets/logo.svg'
import Modal from './components/Modal'
import Footer from './components/Footer'
import FlowMap from './components/FlowMap'
import FlowMapVizualiser from './components/FlowMapVisualiser'
// const countries = 'https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_admin_0_scale_rank.geojson'

// store
import { useModalStore } from './state/ModalStore'
import Paths from './components/Paths'
import Loader from './components/Loader'

import DateSelector from './components/DatesSelector'
import Description from './components/Description'

// theatre js
import flyThroughState from './data/theatrejs/state2.json'
import HomePageHTML from './components/HomePageHTML'

function App () {
  const setModalOpen = useModalStore((state) => state.setModalOpen)

  const wrapperRef = useRef()

  const project = getProject('Fly Through', { state: flyThroughState })
  const sheet = project.sheet('Scene')

  function Wrapper () {
    const orbitControlsRef = useRef()

    const { gl } = useThree()
    const rotationGroupRef = useRef()

    // constructor
    useEffect(() => {
      gl.domElement.parentElement.parentElement.addEventListener('mousedown', () => {
        if (orbitControlsRef.current) {
          orbitControlsRef.current.autoRotate = false
        }
      })
      gl.domElement.parentElement.parentElement.addEventListener('touchdown', () => {
        if (orbitControlsRef.current) {
          orbitControlsRef.current.autoRotate = false
        }
      })

      if (config.scene.flyThrough) {
        project.ready.then(() => {
          sheet.sequence.play({ iterationCount: 1, rate: 1 })
        })
      }
    }, [])

    useFrame((gl, dt) => {
      if (rotationGroupRef.current) {
        if (config.scene.autoRotate) {
          rotationGroupRef.current.rotation.y += dt * config.scene.autoRotateSpeed
        }
      }
    })

    return (
      <>
        <color attach='background' args={[config.scene.bgColor]} />
        <Environment
          path='/images/hdr/'
          files='venice_sunset_1k.hdr'
          blur={0.5}
          intensity={10}
        />
        <OrbitControls
          ref={orbitControlsRef}
          rotateSpeed={0.4}
          maxDistance={15}
          minDistance={config.controls.minDistance}
          autoRotate={config.controls.autoRotate}
          enablePan={config.controls.enablePan}
          autoRotateSpeed={-0.45}
          enableDamping={!isMobile}
          enabled={config.controls.enabled}
        />
        <Stars radius={100} depth={50} count={5000} factor={4} saturation={0} fade speed={1} />
        <group ref={rotationGroupRef}>
          <Main />
          <ParticleRenderer />
          <Markers controlsRef={orbitControlsRef} />
          <Paths />
        </group>
        <Post />
        {config.scene.flyThrough && (
          <PerspectiveCamera
            theatreKey='Camera'
            makeDefault
            position={[config.camera.initPos.x, config.camera.initPos.y, config.camera.initPos.z]}
            fov={20}
            near={0.1}
            far={1000}
          />
        )}
      </>
    )
  }

  return (
    <>
      <div className='wrapper' ref={wrapperRef}>
        {config.showHTML && (
          <>
            <Modal />
            <img className='logo' src={logoImg} alt='Cardano Logo' />
            <Description />
            <DateSelector />
            <Footer />
            <Tooltip id='event-tooltip' />
          </>
        )}
        {config.showHomePageHTML && (
          <>
            <HomePageHTML />
          </>
        )}
      </div>
      <Suspense fallback={<Loader wrapperRef={wrapperRef} />}>
        <Canvas
          shadows
          onCreated={({ gl }) => {
            gl.toneMapping = NoToneMapping
            gl.domElement.parentElement.parentElement.addEventListener('click', () => {
              setModalOpen(false)
            })
          }}
          linear
          gl={{
            antialias: true,
            alpha: true,
            preserveDrawingBuffer: true
          }}
          dpr={[1, 1]}
          camera={{ fov: 20, position: [config.camera.initPos.x, config.camera.initPos.y, config.camera.initPos.z], near: 0.1, far: 1000 }}
        >
          {/* <Perf position='top-left' /> */}
          <SheetProvider sheet={sheet}>
            <Wrapper />
          </SheetProvider>
          <FlowMap />
          <FlowMapVizualiser />
        </Canvas>
      </Suspense>

    </>
  )
}

export default App
