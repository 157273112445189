import { getProject, types } from '@theatre/core'
import { useEffect, useRef } from 'react'

import HTMLContentState from '../data/theatrejs/HTMLContent.theatre-project-state.json'

import { getLS as l } from '../languageStrings'

export default function HomePageHTML () {
  const oneRef = useRef()
  const twoRef = useRef()
  const threeRef = useRef()
  const fourRef = useRef()
  const fiveRef = useRef()
  const ctaRef = useRef()

  const project = getProject('HTML Content', { state: HTMLContentState })
  const sheet = project.sheet('Scene')

  sheet.object('one', {
    opacity: types.number(0, { range: [0, 1] }),
    blur: types.number(0, { range: [0, 10] })
  }, { reconfigure: true }).onValuesChange((values) => {
    if (oneRef.current) {
      oneRef.current.style.opacity = values.opacity
      oneRef.current.style.filter = `blur(${values.blur}px)`
    }
  })

  sheet.object('two', {
    opacity: types.number(0, { range: [0, 1] }),
    blur: types.number(0, { range: [0, 10] })
  }, { reconfigure: true }).onValuesChange((values) => {
    if (twoRef.current) {
      twoRef.current.style.opacity = values.opacity
      twoRef.current.style.filter = `blur(${values.blur}px)`
    }
  })

  sheet.object('three', {
    opacity: types.number(0, { range: [0, 1] }),
    blur: types.number(0, { range: [0, 10] })
  }, { reconfigure: true }).onValuesChange((values) => {
    if (threeRef.current) {
      threeRef.current.style.opacity = values.opacity
      threeRef.current.style.filter = `blur(${values.blur}px)`
    }
  })

  sheet.object('four', {
    opacity: types.number(0, { range: [0, 1] }),
    blur: types.number(0, { range: [0, 10] })
  }, { reconfigure: true }).onValuesChange((values) => {
    if (fourRef.current) {
      fourRef.current.style.opacity = values.opacity
      fourRef.current.style.filter = `blur(${values.blur}px)`
    }
  })

  sheet.object('five', {
    opacity: types.number(0, { range: [0, 1] }),
    blur: types.number(0, { range: [0, 10] })
  }, { reconfigure: true }).onValuesChange((values) => {
    if (fiveRef.current) {
      fiveRef.current.style.opacity = values.opacity
      fiveRef.current.style.filter = `blur(${values.blur}px)`
    }
  })

  sheet.object('CTA', {
    opacity: types.number(0, { range: [0, 1] }),
    blur: types.number(0, { range: [0, 10] })

  }, { reconfigure: true }).onValuesChange((values) => {
    if (ctaRef.current) {
      ctaRef.current.style.opacity = values.opacity
      ctaRef.current.style.filter = `blur(${values.blur}px)`
      if (values.opacity > 0) {
        ctaRef.current.style.display = 'block'
      }
    }
  })

  // constructor
  useEffect(() => {
    project.ready.then(() => {
      sheet.sequence.play({ iterationCount: 1, rate: 1 })
    })
  }, [])

  return (
    <>
      <div className='description-container'>
        <div ref={oneRef} className='text-1'><span>{l.intro_one}</span></div>
        <div ref={twoRef} className='text-2'><span>{l.intro_two}</span></div>
        <div ref={threeRef} className='text-3'><span>{l.intro_three}</span></div>
        <div ref={fourRef} className='text-4'><span>{l.intro_four}</span></div>
        <div ref={fiveRef} className='text-5'><span>{l.intro_five}</span></div>
        <div ref={ctaRef} className='cta'><span><a href='https://voltaire.essentialcardano.io/' target='_blank' rel='noreferrer'>{l.intro_cta}</a></span></div>
      </div>
    </>
  )
}
