import { config } from './config.js'

export const languageStrings = {
  description: {
    title: {
      EN: 'CIP-1694 Global Community Workshops'
    },
    body: {
      EN: 'Join the conversation and contribute to Cardano\'s on-chain governance - wherever you are'
    },
    readMore: {
      EN: 'Read more'
    }
  },
  intro: {
    one: {
      EN: 'Explore the world of CIP-1694',
      JP: 'CIP-1694の世界をご覧ください'
    },
    two: {
      EN: 'Community Workshops are taking place across the globe',
      JP: '今、コミュニティワークショップが世界各地で開催されています'
    },
    three: {
      EN: 'To explore the new proposal for Cardano\'s on-chain governance',
      JP: 'Cardanoオンチェーンガバナンスの新提案を検討し'
    },
    four: {
      EN: 'advancing the blockchain into a self-sustaining system',
      JP: 'コミュニティが、コミュニティのために構築する自立型システムへと'
    },
    five: {
      EN: 'built by the community, for the community',
      JP: 'ブロックチェーンを進化させます'
    },
    cta: {
      EN: 'Discover Now',
      JP: '今すぐチェック'
    }
  }
}

/**
 * Lookup items in object hierarchy based on strings separted with "_"
 */
export const getLS = new Proxy(languageStrings, {
  get (obj, prop) {
    let path = JSON.parse(JSON.stringify(obj))
    const arr = prop.split('_')
    arr.forEach(element => {
      path = path[element]
    })

    // default to EN
    if (typeof path[config.lang] !== 'undefined') {
      return path[config.lang]
    } else {
      return path.EN
    }
  }
})
