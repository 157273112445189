import {
  BufferGeometry,
  Color,
  MeshBasicMaterial,
  CubicBezierCurve3,
  BufferAttribute,
  AdditiveBlending,
  Line
} from 'three'

import { useRef, useEffect } from 'react'

import { geoInterpolate } from 'd3-geo'

import { latLongToCartesian, clamp } from '../helpers/math'

import locations from '../data/workshops.json'

import { config } from '../config'
import { useFrame } from '@react-three/fiber'

export default function Paths () {
  function getSplineFromCoords (coords) {
    const startLat = coords[0]
    const startLng = coords[1]
    const endLat = coords[2]
    const endLng = coords[3]

    // start and end points
    const start = latLongToCartesian(startLat, startLng, config.scene.globeRadius * 1.07)
    const end = latLongToCartesian(endLat, endLng, config.scene.globeRadius * 1.07)

    // altitude
    const altitude = clamp(start.distanceTo(end) * 0.75, config.paths.curveMinAltitude, config.paths.curveMaxAltitude)

    // 2 control points
    const interpolate = geoInterpolate([startLng, startLat], [endLng, endLat])
    const midCoord1 = interpolate(0.25 + (Math.random() * 0.1))
    const midCoord2 = interpolate(0.75 + (Math.random() * 0.1))
    const mid1 = latLongToCartesian(midCoord1[1], midCoord1[0], (config.scene.globeRadius * 1.07) + altitude)
    const mid2 = latLongToCartesian(midCoord2[1], midCoord2[0], (config.scene.globeRadius * 1.07) + altitude)

    return {
      start,
      end,
      spline: new CubicBezierCurve3(start, mid1, mid2, end)
    }
  }

  // refs
  const material = useRef()
  const meshRef = useRef()
  const lineCount = useRef(config.paths.lineCount)
  const counters = useRef([])
  const coords = useRef(locations)

  // Constructor
  useEffect(() => {
    material.current = new MeshBasicMaterial({
      blending: AdditiveBlending,
      opacity: config.paths.opacity,
      transparent: true,
      depthWrite: false,
      //   color: new Color(0xff0000)
      color: new Color(0xffffff)
    })

    for (let index = 0; index < lineCount.current; index++) {
      const randIndex1 = Math.floor(Math.random() * coords.current.length)
      const randIndex2 = Math.floor(Math.random() * coords.current.length)
      addLine(randIndex1, randIndex2)
    }
  }, [])

  function addLine (index1, index2) {
    counters.current.push(Math.floor(Math.random() * config.paths.curveSegments))

    const start = coords.current[index1]
    const end = coords.current[index2]

    if (typeof start === 'undefined' || typeof end === 'undefined') {
      return
    }

    const { spline } = getSplineFromCoords([
      start.lat,
      start.long,
      end.lat,
      end.long
    ])

    // add curve geometry
    const curveGeometry = new BufferGeometry()
    const points = new Float32Array(config.paths.curveSegments * 3)
    const vertices = spline.getPoints(config.paths.curveSegments - 1)

    for (let i = 0, j = 0; i < vertices.length; i++) {
      const vertex = vertices[i]
      points[j++] = vertex.x
      points[j++] = vertex.y
      points[j++] = vertex.z
    }

    curveGeometry.setAttribute('position', new BufferAttribute(points, 3))
    curveGeometry.setDrawRange(0, 0)

    const mesh = new Line(curveGeometry, material.current)

    meshRef.current.add(mesh)
  }

  useFrame((state, dt) => {
    meshRef.current.children.forEach((line, index) => {
      counters.current[index] += (dt * 20.0)

      if (counters.current[index] > config.paths.curveSegments) {
        counters.current[index] = Math.floor(Math.random() * config.paths.curveSegments)
        // counters.current[index] = 0
      }

      line.geometry.setDrawRange(0, counters.current[index])
    })
  })

  return (
    <>
      <mesh ref={meshRef} />
    </>
  )
}
